.header {
  background-color: #1254d6;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header h1 {
  margin: 0;
  font-size: 2.5em;
}

.header p {
  font-size: 1.2em;
}

.profile-imag {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.home-button {
  background-color: #126f94;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
  transition: background-color 0.3s ease;
}

.home-button:hover {
  background-color: #0056b3;
}
