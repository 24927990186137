



.navbar {
    background-color: #112ead;
    padding: 10px;
    display: flex;
    justify-content: center;
  }
  
  .navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 20px;
  }
  
  .navbar li {
    display: inline;
  }
  
  .navbar button {
    background: none;
    color: rgb(245, 242, 242);
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
  }
  
  .navbar button:hover {
    background-color: #151616;
    border-radius: 5px;
  }
  

  @media (max-width: 768px) {
    .navbar-links {
      display: none;
      flex-direction: column;
      width: 100%;
      text-align: center;
    }
  
    .navbar-links.active {
      display: flex;
    }
  
    .toggle-button {
      display: block;
    }
  }
