@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.column {
  width: 100%;
}

@media (min-width: 500px) {
  .column {
    width: 50%;
  }
}

body {
  font-family: "Noto Sans", sans-serif;
}

.home {
  padding: 20px;
}

.iniciopag {
  background-size: cover;
  background-position: center;
  font-size: large;
  padding: 20px 20px 30px 20px;
  margin-right: 5px;
}

.home-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

@media (min-width: 500px) {
  .home-item {
    flex-direction: row;
  }
}

.profile-toledo, .profile, .colegio-a {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 20px;
}

@media (min-width: 500px) {
  .profile-toledo {
    width: 390px;
    height: 300px;
    margin-right: 10px;
  }

  .colegio-a {
    width: 420px;
    height: 250px;
  }

  .profile {
    width: 200px;
    height: 300px;
    margin-top: 0;
  }
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.home-text {
  max-width: 100%;
}

@media (min-width: 500px) {
  .home-text {
    max-width: 600px;
  }
}

.home-text h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.home-text p {
  font-size: 1em;
  line-height: 1.5;
}

.home-text ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 20px 0;
}

.home-text li {
  margin-bottom: 10px;
  list-style-type: none;
}

.firma {
  padding-top: 50px;
  color: azure;
}
