.services {
  padding: 20px;
  text-align: center;
}

.service-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.service-buttons button {
  background-color: #252a3f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
  margin: 5px; /* Add some margin for spacing */
}

.service-buttons button:hover {
  background-color: #0056b3;
}

.service-message {
  font-size: 1.2em;
  color: #333;
  margin-top: 20px;
}

.service-message li {
  text-align: left;
  list-style-type: none;
}

.civ {
  width: 200px;
  height: 300px;
  transition: 0.5s;
}

.civ:hover {
  transform: scale(1.2);
}

@media (max-width: 100px) {
  .service-buttons {
    flex-direction: column; /* Stack buttons vertically */
  }

  .service-buttons button {
    width: 100%; /* Full width buttons */
    padding: 9px;
    font-size: 1.2em;
  }

  .service-message {
    font-size: 1em;
  }

  .civ {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
  }
}


/*aqui carrusel  */

.gallery {
  --d: 10s;
  /* duration */
  display: grid;
  overflow: hidden;
  width: 380px;
  -webkit-mask: linear-gradient(90deg, #0000, #000 10% 90%, #0000);
}

.gallery > img {
  grid-area: 1/1;
  width: 100%;
  aspect-ratio: 1.5;
  object-fit: cover;
  animation: r var(--d) linear infinite;
}

.gallery > img:nth-child(2) {
  animation-delay: calc(-0.2* var(--d));
}

.gallery > img:nth-child(3) {
  animation-delay: calc(-0.4* var(--d));
}

.gallery > img:nth-child(4) {
  animation-delay: calc(-0.6* var(--d));
}

.gallery > img:nth-child(5) {
  animation-delay: calc(-0.8* var(--d));
}

@keyframes r {
  80% {
    transform: translate(-400%);
  }
  80.01% {
    transform: translate(100%);
  }
}
