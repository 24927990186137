@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');


.column {
  width: 100%;
}

@media (min-width: 600px) {
  .column {
    width: 100%;
  
  
body {
  font-style: "Noto Sans", sans-serif;;
  width: fit-content;
}
.App {
  text-align: center;
  font-family: Arial, sans-serif;
  width: 2000px;
}

main {
  padding: 300px;
}

}}
