.floating-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #25d366;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.floating-button img {
  width: 35px;
  height: 35px;
}

.floating-button:hover {
  background-color: #128c7e;
}


