

.form body{background:#59ABE3;
  margin:0}

.form{width:410px;
      height:540px;
      background:#e6e6e6;
      border-radius:8px;
      box-shadow:0 0 40px -10px #000;
      margin:calc(50vh - 220px) auto;
      padding:20px 30px;
      max-width:calc(100vw - 40px);
      box-sizing:border-box;
      font-family:'Montserrat',sans-serif;
      position:relative}

.form h2{margin:10px 0;
        padding-bottom:10px;
        width:180px;
        color:#135bc9;
        border-bottom:3px solid #78788c}

input{width:100%;
      padding:10px;
      box-sizing:border-box;
      background:none;
      outline:none;
      resize:none;
      border:0;
      font-family:'Montserrat',sans-serif;
      transition:all .3s;
      border-bottom:2px solid #bebed2}

input:focus{border-bottom:2px solid #78788c}

p:before{content:attr(type);
        display:block;
        margin:28px 0 0;
        font-size:14px;color:#5a5a5a}
  button{
            cursor:pointer;
              position:relative;
              padding:10px 20px;
              background:white;
              font-size:28px;
              border-top-right-radius:10px;
              border-bottom-left-radius:10px;
              transition:all 1s;
              &:after,&:before{
                content:" ";
                width:10px;
                height:10px;
                position:absolute;
                border :0px solid #fff;
                transition:all 1s;
                }
              &:after{
                top:-1px;
                left:-1px;
                border-top:5px solid black;
                border-left:5px solid black;
              }
              &:before{
                bottom:-1px;
                right:-1px;
                border-bottom:5px solid black;
                border-right:5px solid black;
              }
              &:hover{
                border-top-right-radius:0px;
              border-bottom-left-radius:0px;
                 background:rgba(0,0,0,.5);
                 color:white;
                &:before,&:after{
                  
                  width:100%;
                  height:100%;
                   border-color:white;
                }
              }
            }
            .data-container{
              background:#ffebee;
              height:100vh;
              display:flex;
              justify-content:center;
              align-items:center;
            }
.c1 {content:'Hi';
     position:absolute;
     bottom:-15px;
     right:-20px;
     background:#50505a;
     color:#fff;
     width:320px;
     padding:16px 4px 16px 0;
     border-radius:6px;
     font-size:13px;
     box-shadow:10px 10px 40px -14px #000}


span{margin:0 5px 0 15px}
 

